<template>
    <div>
        <div class="card-toolbar mb-5">
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
<!--                        <div class="form-group col-md-6 mt-2 mb-2">-->
<!--                            <label for="name">{{$t('payment.name')}}</label>-->
<!--                            <input v-model="filters.name" type="text" id="name" class="form-control">-->
<!--                        </div>-->
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('payments.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('payments.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>

<!--                        <div class="form-group col-md-6 mt-2 mb-2">-->
<!--                            <label for="user_id">{{$t('payment.user')}}</label>-->
<!--                            <multiselect-->
<!--                                    v-model="user"-->
<!--                                    id="user_id"-->
<!--                                    :placeholder="$t('payment.user')"-->
<!--                                    label="name"-->
<!--                                    track-by="id"-->
<!--                                    :options="users"-->
<!--                                    :multiple="false"-->
<!--                                    :taggable="false"-->
<!--                                    :show-labels="false"-->
<!--                                    :show-no-options="false"-->
<!--                                    :show-no-results="false"-->
<!--                                    @search-change="getUsers($event)">-->
<!--                            </multiselect>-->

<!--                        </div>-->


<!--                        <div class="form-group col-md-6 mt-2 mb-2">-->
<!--                            <label for="f_status">-->
<!--                                {{$t('status')}}-->
<!--                            </label>-->
<!--                            <select name="" id="f_status" v-model="filters.is_active" type="text" class="custom-select">-->
<!--                                <option value="">{{$t('all')}}</option>-->
<!--                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>-->
<!--                            </select>-->
<!--                        </div>-->
                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{$t('search')}}</span>
                                </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{$t('reset_search')}}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">


<!--                    <template slot="actions" slot-scope="props">-->
<!--                        <router-link v-if="$can('payments.update')" :to="{name: 'plans.details', params:{id: props.row.id}}" class="btn btn-dark btn-sm ml-2 mr-2">-->
<!--                            {{$t('plans.details')}}-->
<!--                        </router-link>-->
<!--                    </template>-->
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        components: {},
        data() {
            return {
                mainRoute: '/subscription/subscription/payment_transactions',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,

                filters: {
                    // user_id: null,
                    // name: null,
                    from_date: null,
                    to_date: null,
                    // is_active: null,
                },

                // status_list: [
                //     {id: 1, text: this.$t('active')},
                //     {id: 0, text: this.$t('inactive')},
                // ],
                columns: ['payer_id', 'payment_method', 'amount', 'currency', 'payment_id'],


                users: [],
                validation: [],
                dataList : [],
                user : null,

            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        payer_id: that.$t('payments.payer_id'),
                        payment_method: that.$t('payments.payment_method'),
                        amount: that.$t('payments.amount'),
                        currency: that.$t('payments.currency'),
                        payment_id: that.$t('payments.payment_id'),

                        // actions: that.$t('actions'),

                    },
                    sortable: ['created_at'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            payment_method: 'cash',
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params}).catch(function (error) {
                            console.error(error)
                        })

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        // watch: {
        //     user: function (val) {
        //         if (val) {
        //             this.filters.user_id = val.id;
        //         } else {
        //             this.filters.user_id = null;
        //         }
        //     },
        // },
        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.payments_cash")}]);
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                // this.filters.user_id = null;
                // this.filters.name = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                // this.filters.is_active = null;
                // this.user = null;

                this.$refs.table.refresh();
            },
            actionDelete(id) {
                ApiService.delete(this.mainRoute + "/" + id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch(({errors}) => {
                    console.error(errors);
                })
            },
            // editItem(id) {
            //     this.$router.push({name: 'plans.edit', params: {id: id}});
            // },
            // deleteItem(id) {
            //     this.$confirmAlert('', this.actionDelete, id);
            // },
            //
            // changeStatus(id, status) {
            //     ApiService.patch(this.subMainRoute + '/change-status/' + id, {
            //         is_active: (status ? '1' : '0'),
            //     }).then(response => {
            //         this.$refs.table.refresh();
            //         this.$successAlert(response.data.message);
            //     }).catch(error => {
            //         this.$errorAlert(error);
            //     });
            // },

            // getUsers(filter) {
            //     if(filter && filter.length >= 3){
            //         ApiService.get(`${this.mainRouteDependency}/users`,{params:{filter: filter}}).then((response) => {
            //             this.users = response.data.data;
            //         });
            //     }
            // },

        },
    };
</script>
